<script setup>

    import Multiselect from 'vue-multiselect'
    const modelValue = defineModel()
    const commercialStore = useCommercialStore()
    const app_config = useAppConfig()

    const items = computed( () => {
        let offer = 'arenda'
        if( commercialStore.filter.offer == 'prodaja' ){
            offer = 'prodaja'
        }
        let offer_data = app_config[ offer ]
        let _ = []
        offer_data.prices_units.map( item => {
            _.push( item )
        })
        return _
    })  

    // console.log('modelValue', modelValue.value);

    // const changeValue = () => {
    //     let def_type = items.value.find( e => e.default == true )
    //     commercialStore.filter.price_from = def_type
    //     last_set_value.value = commercialStore.filter.offer
    // }

    // const last_set_value = ref('аренда')
    // watch( commercialStore.filter, () => {
    //     if( commercialStore.filter.offer !== last_set_value.value ){
    //         let def_type = items.value.find( e => e.default == true )
    //         commercialStore.filter.price_from = def_type
    //         last_set_value.value = commercialStore.filter.offer
    //     }
    // }, { deep: true })

    // onMounted( () => {
    //     if( modelValue.value ) changeValue()
    // })

</script>
<template>
    <div>
        <multiselect
            class="bright mini"
            v-model="modelValue"
            :searchable="false"
            :show-labels="false"
            :options="items"
            :allowEmpty="false"
            :multiple="false"
            label="label"
            :close-on-select="true"
            track-by="id"
            placeholder="Цена за"
        />
    </div>
</template>
<style>
</style>