<script setup>

const modelValue = defineModel()
const active_tab = ref(0)
const items = ref([
    // { 
    //     id: null,
    //     label: 'Все'
    // },
    { 
        id: 'arenda',
        // id: 'аренда',
        label: 'Аренда'
    },
    { 
        id: 'prodaja',
        // id: 'продажа',
        label: 'Продажа'
    },
])

onMounted(() => {
    itemSelected( modelValue.value )
})

const itemSelected = value => {
    active_tab.value = value
    modelValue.value = value
    return true
}

</script>
<template>
    <div class="inline-block">
        <div class="flex items-center w-auto rounded-md border border-brand-blue overflow-hidden">
            <div 
                v-for="item in items" 
                :key="item.id" 
                @click="itemSelected( item.id )"
                :class="active_tab === item.id ? 'bg-brand-blue text-white' : 'text-brand-blue'"
                class="cursor-pointer px-3 py-1 border-s border-brand-blue -ms-[1px]"
            >
                {{ item.label }}
            </div>
        </div>
    </div>
</template>