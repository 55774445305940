import { defineStore } from 'pinia'
import axios from 'axios'

export const useSearchStore = defineStore('searchStore', {
    state: () => ({
        items: [],
    }),
    actions: {
        async getList( text ){
            const { data } = await axios.get(`/api/search?text=${text}`)
            this.items = data
        }
    }
})