<script setup>
    import Multiselect from 'vue-multiselect'
    const modelValue = defineModel()
    const app_config = useAppConfig()

    const items = computed( () => {
      
        let data = app_config['naznacheniya']
        let _ = []
        data.map( item => {
            item.label = capitalize(item.header)
            _.push( item )
        })
        return _
    })
</script>
<template>
    <div>
        <multiselect
            class="bright"
            v-model="modelValue"
            :searchable="true"
            :show-labels="false"
            :options="items"
            :allowEmpty="true"
            :multiple="false"
            label="label"
            :close-on-select="true"
            track-by="label"
            placeholder="Тип"
        >
            <template #selection="{ values, search, isOpen }">
                <span v-if="values.length <= 1" v-show="!isOpen">
                    {{ values.map(e => e.label).join(', ') }}
                </span>
                <span v-if="values.length > 1" v-show="!isOpen">
                    {{ values.length }} выбрано
                </span>
            </template>
        </multiselect>
    </div>
</template>
<style scoped>



</style>