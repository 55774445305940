<script setup>
    const modelValue = defineModel()
    const { classes, type, placeholder } = defineProps({
        classes: {
            type: String,
            default: 'bg-brand-input p-2 rounded md:w-[146px] w-1/2'
        }, 
        type: {
            type: String,
            default: ''
        }, 
        placeholder: {
            type: String,
            default: ''
        }
    })
</script>
<template>
    <input :type="type" :placeholder="placeholder" :class="classes" v-model="modelValue" />
</template>