<script setup>

const showFilter = ref(false);

const metrosStore = useMetrosStore()
const raionsStore = useRaionsStore()
const searchStore = useSearchStore()
const commercialStore = useCommercialStore()
const route = useRoute()
const router = useRouter()

const { query, autorun } = defineProps({
    query: {
        type: Object,
        default: {}
    },
    autorun: {
        type: Boolean,
        default: false
    }
})

// console.log('FILTER query', query);

onMounted(async () => {
    await raionsStore.getList()
    await metrosStore.getList()
    await commercialStore.parseParams(query)
    if (autorun) await commercialStore.applyFilter()
})

const isClosed = computed(() => (commercialStore.filter.search.length > 0 ? true : false));

const searchEmpty = async () => {
    commercialStore.filter.search = "";
    await commercialStore.applyFilter(true)
    return true;
};

const runPodbor = async () => {
    commercialStore.seo_mode = false
    commercialStore.showList = false
    await commercialStore.applyFilter(true)
    commercialStore.showList = true
    showFilter.value = false
    return true;
};

const searchInputXnr = ref(null)
const isSearchVisible = ref(false);
const searchInput = event => {
    let search = event.target.value
    // console.log('searchInput', search);
    // Если инпут пустой, не отправляем запрос и скрываем результаты поиска 
    if (!search) { 
        isSearchVisible.value = false; return; 
    }
    
    if (searchInputXnr.value) clearTimeout(searchInputXnr.value)
    searchInputXnr.value = setTimeout(async () => {
        await searchStore.getList(search)
        isSearchVisible.value = searchStore.items.length > 0;
    }, 1000);
    if (!search) isSearchVisible = false;
}

// Вычисляемое свойство для видимости поиска 
const computedIsSearchVisible = computed(() => isSearchVisible.value);

const searchNavigate = item => {
    router.push(item.link)
    searchStore.items = []
}


</script>
<template>
    <div class="relative z-50 w-full">
        <div data-filter class="absolute w-full" v-if="showFilter">
            <div class="bg-white border border-custom-gray rounded-lg w-full overflow-hidden">

                <div data-filter="tool-panel" class="flex p-2 bg-white justify-between">
                    <div class="w-5/5 sm:w-1/5  md:pb-0">
                        <button @click="showFilter = !showFilter" type="button"
                            class="text-white bg-brand-primary hover:bg-brand-primary-hover py-2.5 px-3 md:py-1.5 md:px-12 rounded-lg font-medium sm:py-2 sm:px-12">
                            <svg class="block sm:hidden" v-if="showFilter === false" width="14" height="14"
                                viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.3333 0H0.666667C0.489856 0 0.320286 0.0720853 0.195262 0.200398C0.0702379 0.328711 0 0.50274 0 0.684202V2.20792L0.00533326 2.3605C0.0385388 2.81735 0.219709 3.24967 0.52 3.58864L3.33333 6.76402V12.3156C3.33331 12.424 3.35839 12.5309 3.4065 12.6274C3.45461 12.7239 3.52438 12.8073 3.61006 12.8708C3.69574 12.9342 3.79488 12.9758 3.8993 12.9922C4.00373 13.0085 4.11045 12.9992 4.21067 12.9649L8.21067 11.5965L8.28267 11.5671C8.39748 11.512 8.49458 11.4243 8.56259 11.3145C8.63061 11.2048 8.66671 11.0773 8.66667 10.9472V6.44108L11.414 3.62216C11.5999 3.43144 11.7473 3.20499 11.8479 2.95575C11.9484 2.7065 12.0001 2.43936 12 2.1696V0.684202C12 0.50274 11.9298 0.328711 11.8047 0.200398C11.6797 0.0720853 11.5101 0 11.3333 0Z"
                                    fill="white" />
                            </svg>
                            <svg class="block sm:hidden" width="14" height="14" v-else viewBox="0 0 12 13" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L1 13" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 1L13 13" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p class="hidden sm:block text-base">Закрыть</p>
                        </button>
                    </div>
                    <div class="w-5/5 md:w-3/5 flex items-center justify-center">
                        <span class="ms-0 text-lg font-normal">Расширенный фильтр</span>
                    </div>
                    <div class="w-5/5 sm:w-1/5  md:pb-0 flex justify-end">
                        <button @click="commercialStore.resetFilter" type="button"
                            class="text-white brand-btn light py-1.5 px-3 md:py-1.5 md:px-12 rounded-lg font-medium sm:py-2 sm:px-12 z-10">
                            <svg class="block sm:hidden" width="20" height="20" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L1 13" stroke="#8F8F8F" stroke-opacity="0.79" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path d="M1 1L13 13" stroke="#8F8F8F" stroke-opacity="0.79" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                            <p class="hidden sm:block text-base">Сбросить</p>
                        </button>
                    </div>
                </div>

                <div data-filter="offertype"
                    class="flex pt-2 md:p-4 flex-col md:flex-row md:items-center px-2 bg-white md:even:bg-gray-50 md:odd:bg-white">
                    <div class="w-5/5 sm:w-1/5 pb-2 md:pb-0">
                        <p class="text-base text-brand-grey">Категория</p>
                    </div>
                    <div class="w-5/5 md:w-4/5">
                        <UiOffer v-model="commercialStore.filter.offer" />
                    </div>
                </div>

                <div data-filter="type"
                    class="flex pt-6 md:p-4 flex-col gap-2 md:flex-row md:items-center px-2 even:bg-gray-50 odd:bg-white">
                    <div class="w-5/5 sm:w-1/5">
                        <p class="text-base text-brand-grey">Тип</p>
                    </div>
                    <div class="w-5/5 md:w-4/5 hidden lg:block">
                        <UiType v-model="commercialStore.filter.type" />
                    </div>
                    <div class="w-5/5 md:w-4/5 block lg:hidden">
                        <UiSelectType v-model="commercialStore.filter.type" />
                    </div>

                </div>
                <div v-show="commercialStore.filter.type == 'vstroenniy-pomeshenia'"
                    class="flex pt-6 md:p-4 md:bg-brand-bright flex-col md:flex-row md:items-center px-2 even:bg-gray-50 odd:bg-white">
                    <div class="w-full pb-3 md:pb-0 flex items-center justify-between md:w-1/5">
                        <p class="text-base text-brand-grey">Назначение</p>
                    </div>
                    <div class="w-full md:w-[300px]">
                        <UiSelectVstroeniyPomeshenia v-model="commercialStore.filter.subtype" />
                    </div>
                </div>
                <div data-filter="price"
                    class="flex pt-6 md:p-4 md:bg-brand-bright flex-col md:flex-row md:items-center px-2 even:bg-gray-50 odd:bg-white">
                    <div class="w-full pb-3 md:pb-0 flex items-center justify-between md:w-1/5">
                        <p class="text-base text-brand-grey">Цена</p>
                    </div>
                    <div class="w-5/5 md:w-4/5">
                        <div class="flex items-center md:gap-2 gap-4 md:flex-row flex-col">
                            <UiInput v-model="commercialStore.filter.price_ot" type="text" placeholder="от"
                                classes="bg-brand-input p-2 rounded md:w-[146px] w-full border-none" />
                            <UiInput v-model="commercialStore.filter.price_do" type="text" placeholder="до"
                                classes="bg-brand-input p-2 rounded md:w-[146px] w-full border-none" />
                            <div class="md:w-32 w-full">
                                <UiSelectUnits v-model="commercialStore.filter.price_from" />
                            </div>
                            <p class="text-brand-blue text-base md:block hidden"></p>
                        </div>
                    </div>
                </div>

                <div data-filter="area"
                    class="flex pt-6 md:p-4 md:bg-brand-bright flex-col md:flex-row md:items-center px-2 even:bg-gray-50 odd:bg-white">
                    <div class="w-full pb-3 md:pb-0 flex items-center justify-between md:w-1/5">
                        <p class="text-base text-brand-grey">Площадь</p>
                        <p class="text-brand-blue text-base block md:hidden">кв.м.</p>
                    </div>
                    <div class="flex items-center gap-2 w-5/5 md:w-4/5">
                        <UiInput v-model="commercialStore.filter.area_ot" type="text" placeholder="от"
                            classes="bg-brand-input p-2 rounded md:w-[146px] w-1/2 border-none" />
                        <UiInput v-model="commercialStore.filter.area_do" type="text" placeholder="до"
                            classes="bg-brand-input p-2 rounded md:w-[146px] w-1/2 border-none" />
                        <p class="text-brand-blue text-base hidden md:block">кв.м.</p>
                    </div>
                </div>

                <div data-filter="raions"
                    class="pt-6 md:p-4 flex flex-col md:flex-row md:items-center px-2 even:bg-gray-50 odd:bg-white">
                    <div class="w-1/5 pb-2 md:pb-0">
                        <p class="text-base text-brand-grey">Районы</p>
                    </div>
                    <div class="flex items-center gap-2 w-full md:w-[300px]">
                        <UiSelectRaions v-model="commercialStore.filter.raions"
                            :city_id="commercialStore.filter.city_id" />
                    </div>
                </div>

                <div data-filter="metros"
                    class="pt-6 md:p-4 flex flex-col md:flex-row md:items-center md:bg-brand-bright px-2 even:bg-gray-50 odd:bg-white">
                    <div class="w-1/5 pb-2 md:pb-0">
                        <p class="text-base text-brand-grey">Метро</p>
                    </div>
                    <div class="flex items-center gap-2 w-full md:w-[300px]">
                        <UiSelectMetros v-model="commercialStore.filter.metros"
                            :city_id="commercialStore.filter.city_id" />
                    </div>
                </div>

                <div data-filter="search"
                    class="pt-6 md:p-4 flex flex-col md:flex-row md:items-center px-2 even:bg-gray-50 odd:bg-white">
                    <div class="md:w-1/5 pb-2 md:pb-0 w-full">
                        <p class="text-base text-brand-grey">Произвольный текст</p>
                    </div>
                    <div class="flex items-center gap-2 w-5/5 md:w-4/5">
                        <UiInput v-model="commercialStore.filter.search" type="text" placeholder="поиск"
                            classes="bg-brand-input p-2 rounded w-full md:w-[304px] border-none" />
                    </div>
                </div>

                <div data-filter="buttons" class="category flex items-center md:p-4 px-2 py-3 pt-0 md:pt-4">
                    <div class="md:w-1/5"></div>
                    <div class="flex items-center gap-2 w-full md:w-4/5 justify-between md:justify-start pt-4 md:pt-0 ">
                        <button class="brand-btn" @click="runPodbor()">Применить</button>
                        <button class="brand-btn light sm:hidden " @click="commercialStore.resetFilter">Сбросить</button>
                    </div>
                </div>

            </div>
        </div>
        <div data-search
            class="bar py-2 px-2.5 md:p-2 bg-white rounded-lg flex items-center gap-3 md:border-none border border-custom-gray">

            <button data-search="close" type="button" @click="showFilter = !showFilter"
                class="text-white bg-brand-primary hover:bg-brand-primary-hover py-2.5 px-3 md:py-1.5 md:px-12 rounded-lg font-medium sm:py-2 sm:px-12">
                <svg class="block sm:hidden" v-if="showFilter === false" width="14" height="14" viewBox="0 0 12 13"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.3333 0H0.666667C0.489856 0 0.320286 0.0720853 0.195262 0.200398C0.0702379 0.328711 0 0.50274 0 0.684202V2.20792L0.00533326 2.3605C0.0385388 2.81735 0.219709 3.24967 0.52 3.58864L3.33333 6.76402V12.3156C3.33331 12.424 3.35839 12.5309 3.4065 12.6274C3.45461 12.7239 3.52438 12.8073 3.61006 12.8708C3.69574 12.9342 3.79488 12.9758 3.8993 12.9922C4.00373 13.0085 4.11045 12.9992 4.21067 12.9649L8.21067 11.5965L8.28267 11.5671C8.39748 11.512 8.49458 11.4243 8.56259 11.3145C8.63061 11.2048 8.66671 11.0773 8.66667 10.9472V6.44108L11.414 3.62216C11.5999 3.43144 11.7473 3.20499 11.8479 2.95575C11.9484 2.7065 12.0001 2.43936 12 2.1696V0.684202C12 0.50274 11.9298 0.328711 11.8047 0.200398C11.6797 0.0720853 11.5101 0 11.3333 0Z"
                        fill="white" />
                </svg>
                <svg class="block md:hidden" width="14" height="14" v-else viewBox="0 0 12 13" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L1 13" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1 1L13 13" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p class="hidden sm:block text-base">Фильтр</p>
            </button>

            <div data-search="input" class="flex items-center w-full relative">
                <input v-if="!showFilter "
                    class="md:block hidden w-full py-[6px] border-none rounded md:rounded-lg outline-none h-full text-base md:text-lg text-start ps-1 md:ps-10"
                    type="text" placeholder="Поиск по названию, району, метро, улице" @input="searchInput" />
                <div v-if="!showFilter"  class="relative overflow-hidden">
                    <input
                    class="showPlaceholder md:hidden block bg-transparent w-full md:py-0.5 py-[6px] border-none rounded md:rounded-lg outline-none h-full text-base md:text-lg text-start ps-1 md:ps-10"
                    
                    type="text" placeholder=" " @input="searchInput" />
                    <div :class="!showFilter ? 'placeholderText' : ''" placeholder="Поиск по названию, району, метро, улице"></div>
                </div>
                
                <button type="button" v-if="isClosed" @click="searchEmpty" class="me-10">
                    <svg class="mx-2" width="20" height="20" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L1 13" stroke="#8F8F8F" stroke-opacity="0.79" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M1 1L13 13" stroke="#8F8F8F" stroke-opacity="0.79" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
            </div>

            <button data-search="clear" type="button" @click="commercialStore.applyFilter(true)"
                class="text-white bg-brand-primary py-1.5 px-4 md:py-1.5 md:px-12 rounded-lg font-medium hover:bg-brand-primary-hover ">
                <p class="block text-base">Поиск</p>
            </button>

        </div>
        <div data-search-result class="absolute w-full mt-1" v-if="computedIsSearchVisible">
            <div class="bg-white border border-custom-gray rounded-lg w-full overflow-auto h-[50vh] custom-scroll">
                <template v-for="item in searchStore.items">
                    <div class="w-full">
                        <div class="w-full flex md:flex-row flex-col items-center md:border-none border-b hover:bg-blue-100 py-3 cursor-pointer" @click="searchNavigate(item)">
                            <div class="md:w-1/6 flex items-center justify-end">
                                <p class="my-0 text-base font-semibold">{{ item.type }}</p>
                            </div>
                            <div class="md:w-4/6 flex items-center justify-start px-3">
                                <p class="my-0 text-base font-normal italic">{{ item.header }}</p>
                            </div>
                            <div class="md:w-1/6 text-sm text-gray-400">{{ item.description }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>
<style scoped>
/* @keyframes scrollPlaceholder { 
        0% { 
            text-indent: 0%; 
            white-space: nowrap; 
            } 
        50% { 
            text-indent: -50%; 
            white-space: nowrap; 
            }
        100% {
            text-indent: -100%; 
            } 
    }
    .input_search{
        animation: scrollPlaceholder 6s ease infinite;
    } */
.showPlaceholder+.placeholderText:before {
    content: attr(placeholder);
    position: absolute;
    left: 10px;
    top: 5px;
    white-space: nowrap;
    /* no line break */
    color: grey;
    pointer-events: none;
    /* avoid any interaction */
    animation: 6s scrollText infinite;
    z-index: 0;
}

.showPlaceholder:focus+.placeholderText:before {
    content: '';
    animation: none;
}

@keyframes scrollText {
    to {
        transform: translateX(calc(150px - 100%));
    }
}

.custom-scroll::-webkit-scrollbar {
  width: 7px; /* Ширина скроллбара */
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #fafafa; /* Цвет фона скроллбара */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #e1eef7; /* Цвет ползунка скроллбара */
  border-radius: 4px; /* Скругление углов ползунка */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #8cb2d9; /* Цвет ползунка скроллбара при наведении */
}
</style>