<script setup>
import Multiselect from 'vue-multiselect'
const modelValue = defineModel()
const commercialStore = useCommercialStore()
const app_config = useAppConfig()

const props = defineProps({
    type: String
})
// const items = ref([
//     'Офисное помещение',
//     'Складское помещение',
//     'Встроенное помещение',
//     'Земельный участок',
//     'Отдельно стоящее здание',
//     'Производственное помещение'
// ])

const items = computed(() => {
    let offer = 'arenda'
    if (commercialStore.filter.offer == 'prodaja') {
        offer = 'prodaja'
    }
    let offer_data = app_config[offer]
    let _ = []
    offer_data.types.map(item => {
        item.label = capitalize(item.label)
        _.push(item)
    })
    return _
})
</script>
<template>
    <div>
        <multiselect class="bright  w-full" 
            :class="{ 'type_multiselect': props.type === 'form_podbor' }"
            v-model="modelValue" 
            :searchable="true" 
            :show-labels="false" 
            :options="items" 
            :allowEmpty="true"
            :multiple="true" 
            label="label" 
            :close-on-select="false" 
            :clear-on-select="false" 
            track-by="label"
            placeholder="Тип">
            <template #selection="{ values, search, isOpen }">
                <span v-if="values.length <= 1" v-show="!isOpen">
                    {{ values.map(e => e.label).join(', ') }}
                </span>
                <span v-if="values.length > 1" v-show="!isOpen">
                    {{ values.length }} выбрано
                </span>
            </template>
        </multiselect>

    </div>
</template>
<style>
.multiselect.type_multiselect>.multiselect__tags {
    @apply !bg-gray-50 !border !border-gray-300 rounded-lg;
}

.multiselect.type_multiselect>.multiselect__tags>.multiselect__placeholder {
    @apply !text-gray-600;
}


</style>