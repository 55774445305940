<script setup>
import Multiselect from 'vue-multiselect'

const modelValue = defineModel()
const raionsStore = useRaionsStore()
await raionsStore.getList()

// onMounted( async () => {
// })
const { city_id } = defineProps({
    city_id: {
        type: Number,
        default: 1
    }
})


</script>
<template>
  <multiselect
      class="bright w-[300px]"
      v-model="modelValue"
      :searchable="true"
      :show-labels="false"
      :options="raionsStore.items.filter( e => e.city_id == city_id )"
      :allowEmpty="true"
      :multiple="true"
      label="header"
      :close-on-select="false"
      track-by="header"
      placeholder="Район"
  >
    <template #selection="{ values, search, isOpen }">
                <span v-if="values.length <=2" v-show="!isOpen">
                    {{ values.map(e => e.header).join(', ') }}
                </span>
      <span v-if="values.length > 2" v-show="!isOpen">
                    {{ values.length }} выбрано
                </span>
    </template>
  </multiselect>
</template>