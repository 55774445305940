<script setup>

const modelValue = defineModel()
const commercialStore = useCommercialStore()
const app_config = useAppConfig()


const items = computed( () => {
    let offer = 'arenda'
    if( commercialStore.filter.offer == 'продажа' ){
        offer = 'prodaja'
    }
    let offer_data = app_config[ offer ]
    let _ = []
    offer_data.types.map( item => {
        _.push( item )
    })
    return _
})

const selectValue = item => {
    modelValue.value = item.slug
    return true
}

</script>
<template>
    <div class="block">
        <div class="flex gap-2">
            <div 
                v-for="item in items" :key="item" 
                @click="selectValue( item )"
                class="overflow-hidden relative bg-cover bg-no-repeat min-h-[211px] h-full w-[175px] rounded-2xl flex flex-col justify-end cursor-pointer md:flex !h-[169px] min-h-fit text-xs"
                :style="{ backgroundImage: `url('/img/types/${item.image}')` }"
            >
                <div v-if="modelValue == item.slug" class="w-full h-full absolute z-20 border-[3px] border-brand-blue rounded-2xl"></div>
                <div class="bg-[#2C2C2CB8] rounded-b-2xl p-2 text-white h-[60px] z-10">
                    <p class="text-sm text-center flex flex-column justify-center items-center h-full">{{ capitalize( item.label ) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>